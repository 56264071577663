@import url("https://fonts.googleapis.com/css?family=Quicksand:400,700&display=swap");

html {
  background-color: #ffdf76 !important;

}

body {
  width: 100% !important;
  height: 100% !important;
  margin: 0;
  -webkit-text-size-adjust: none;
  background-color: #ffdf76 !important;
  position: absolute;
  top: 0;
  bottom: 0;
}

#root {
  height: 100%
}

.email-content {
  height: 100%
}

a {
  color: black;
}

a img {
  border: none;
}

td {
  word-break: break-word;
}

.preheader {
  display: none !important;
  visibility: hidden;
  mso-hide: all;
  font-size: 1px;
  line-height: 1px;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
}

/* Type ------------------------------ */

body,
td,
th {
  font-family: "Quicksand", "Helvetica Neue", "Helvetica", "Arial",
    sans-serif;
}

h1 {
  margin-top: 0;
  color: #222;
  font-size: 22px;
  font-weight: bold;
  text-align: left;
}

h2 {
  margin-top: 0;
  color: #222;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
}

h3 {
  margin-top: 0;
  color: #222;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}

td,
th {
  font-size: 16px;
}

p,
ul,
ol,
blockquote {
  margin: 0.4em 0 1.1875em;
  font-size: 16px;
  line-height: 1.625;
}

p.sub {
  font-size: 13px;
}

/* Utilities ------------------------------ */

.align-right {
  text-align: right;
}

.align-left {
  text-align: left;
}

.align-center {
  text-align: center;
}

/* Buttons ------------------------------ */

.button {
  background-color: #3869d4;
  border-top: 10px solid #3869d4;
  border-right: 18px solid #3869d4;
  border-bottom: 10px solid #3869d4;
  border-left: 18px solid #3869d4;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  border-radius: 10px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.16);
  width: 100%;

  padding-top: 3px;
  padding-bottom: 3px;
  -webkit-text-size-adjust: none;
  box-sizing: border-box;
}

.button--green {
  background-color: #1a8076;
  border-top: 10px solid #1a8076;
  border-right: 18px solid #1a8076;
  border-bottom: 10px solid #1a8076;
  border-left: 18px solid #1a8076;
}

.button--gray {
  background-color: #a3a3a3;
  border-top: 10px solid #a3a3a3;
  border-right: 18px solid #a3a3a3;
  border-bottom: 10px solid #a3a3a3;
  border-left: 18px solid #a3a3a3;
}

.button--red {
  background-color: #ff6136;
  border-top: 10px solid #ff6136;
  border-right: 18px solid #ff6136;
  border-bottom: 10px solid #ff6136;
  border-left: 18px solid #ff6136;
}

@media only screen and (max-width: 500px) {
  .button {
    width: 100% !important;
    text-align: center !important;
  }
}

/* Attribute list ------------------------------ */

.attributes {
  margin: 0 0 21px;
}

.attributes_content {
  background-color: red;
  padding: 16px;
}

.attributes_item {
  padding: 0;
}

/* Discount Code ------------------------------ */

.discount {
  width: 100%;
  margin: 0;
  padding: 24px;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  background-color: red;
  border: 2px dashed #cbcccf;
}

.discount_heading {
  text-align: center;
}

.discount_body {
  text-align: center;
  font-size: 15px;
}

/* Social Icons ------------------------------ */

.social {
  width: auto;
}

.social td {
  padding: 0;
  width: auto;
}

.social_icon {
  height: 20px;
  margin: 0 8px 10px 8px;
  padding: 0;
}

/* Data table ------------------------------ */

.purchase {
  width: 100%;
  margin: 0;
  padding: 35px 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
}

.purchase_content {
  width: 100%;
  margin: 0;
  padding: 25px 0 0 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
}

.purchase_item {
  padding: 10px 0;
  color: #51545e;
  font-size: 15px;
  line-height: 18px;
}

.purchase_heading {
  padding-bottom: 8px;
  border-bottom: 1px solid #eaeaec;
}

.purchase_heading p {
  margin: 0;
  color: #85878e;
  font-size: 12px;
}

.purchase_footer {
  padding-top: 15px;
  border-top: 1px solid #eaeaec;
}

.purchase_total {
  margin: 0;
  text-align: right;
  font-weight: bold;
  color: #333333;
}

.purchase_total--label {
  padding: 0 15px 0 0;
}

body {
  background-color: #fff;
  color: #000;
}

p {
  color: #51545e;
}

p.sub {
  color: #6b6e76;
}

.email-wrapper-top {
  width: 100%;
  margin: 0;
  padding: 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  background-color: #fee07d;
  position: absolute;
  bottom: 0;
}

.email-content {
  width: 100%;
  margin: 0;
  padding: 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
}

/* Masthead ----------------------- */

.email-masthead {
  padding: 25px 0;
  text-align: center;
}

.email-masthead_logo {
  width: 94px;
}

.email-masthead_name {
  font-size: 16px;
  font-weight: bold;
  color: #a8aaaf;
  text-decoration: none;
  text-shadow: 0 1px 0 white;
}

/* Body ------------------------------ */

.email-body {
  width: 100%;
  margin: 0;
  padding: 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  background-color: #ffffff;
}

.email-body_inner {
  width: 570px;
  margin: 0 auto;
  padding: 0;
  -premailer-width: 570px;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  background-color: #ffffff;
}

.email-footer {
  width: 570px;
  height: 100%;
  margin: 0 auto;
  padding: 0;
  -premailer-width: 570px;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  text-align: center;
  background: #ffdf76 url("https://www.lamaisonducoworking.fr/wp-content/themes/lmdc/assets/img/footer-logo.svg") 10% 20px no-repeat;
}

.email-footer p {
  color: black;
}

.body-action {
  width: 100%;
  margin: 30px auto;
  padding: 0;
  -premailer-width: 100%;
  -premailer-cellpadding: 0;
  -premailer-cellspacing: 0;
  text-align: center;
}

.body-sub {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #eaeaec;
}

.content-cell {
  padding: 35px;
}

/*Media Queries ------------------------------ */

@media only screen and (max-width: 600px) {

  .email-body_inner,
  .email-footer {
    width: 100% !important;
  }
}

.logo>span {
  display: block;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1;
  text-transform: uppercase;
}

.logo>span span {
  display: block;
  font-size: 1.125rem;
}